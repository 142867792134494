import Vue from "vue";
import myMessage_com from './myMessage.vue';
const myMessageConstructor = Vue.extend(myMessage_com);
export default function myMessage(options){
	//1.实例化
	const instance = new myMessageConstructor({data:options});
	//2.手动挂载
	instance.$mount();//dom元素渲染完成
	//手动挂载到body中
	document.body.appendChild(instance.$el);  //instance.$el 获取当前的dom对象
	return instance;
}