export const deteleObject = (obj) => { //数组对象去重
	var uniques = [];
	var stringify = {};
	for (var i = 0; i < obj.length; i++) {
		var keys = Object.keys(obj[i]);
		keys.sort(function(a, b) {
			return (Number(a) - Number(b));
		});
		var str = '';
		for (var j = 0; j < keys.length; j++) {
			str += JSON.stringify(keys[j]);
			str += JSON.stringify(obj[i][keys[j]]);
		}
		if (!stringify.hasOwnProperty(str)) {
			uniques.push(obj[i]);
			stringify[str] = true;
		}
	}
	uniques = uniques;
	return uniques;
}
export const randomNum = (minNum, maxNum) => { //生成从minNum到maxNum的随机数
	switch (arguments.length) {
		case 1:
			return parseInt(Math.random() * minNum + 1, 10);
			break;
		case 2:
			return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
			break;
		default:
			return 0;
			break;
	}
}

export const current =()=> {
	var d = new Date(),
	str = '';
	str += d.getFullYear() + '年'; //获取当前年份 
	str += d.getMonth() + 1 + '月'; //获取当前月份（0——11） 
	str += d.getDate() + '日';
	str += d.getHours() + '时';
	str += d.getMinutes() + '分';
	str += d.getSeconds() + '秒';
	return str;
}
