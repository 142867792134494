<template>
	<div id="myMessageUI">
		<div class="Mymessage" v-if="isShow">
			<div class="MymessageSon">
			    <span :class="icon" :style="color"></span> {{msg}}
			</div>
		</div>
	</div>
</template>
<!--el-icon-success-->
<script>
	export default{
		data(){
			return{
				msg:'',
				duration:null,
				icon:null,
				color:null,
				isShow:false,
			}
		},
		methods:{
			
		},
		mounted() {
			this.isShow = true;
			setTimeout(()=>{
				this.isShow = false;
			},this.duration);
		}
	}
</script>

<style scoped="scoped">
#myMessageUI{
	font-size: 12px;
	position: fixed;
	z-index: 1010;
	width: 100%;
	top: 16px;
	left: 0;
}
.Mymessage{
	width: auto;
	vertical-align: middle;
	position: absolute;
	left: 50%;
}
.MymessageSon{
	position: relative;
	right: 50%;
	padding: 8px 16px;
	border-radius: 4px;
	box-shadow: 0 1px 6px rgba(0,0,0,.2);
	background: #fff;
	display: block;
}
</style>
