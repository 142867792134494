export const getUserInfo = () => {
	return JSON.parse(window.localStorage.getItem('adminInfo'));
};
export const removeToken = () => {
	return window.localStorage.removeItem('adminInfo');
};
export const setUSERid = (val)=>{
	return window.localStorage.setItem('qq_user_id',val);
}
export const getUSERid = ()=>{
	return JSON.parse(window.localStorage.getItem('qq_user_id'));
}