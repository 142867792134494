import Vue from 'vue'
import Vuex from 'vuex'
import { deteleObject } from'../server/api/tool.js' //导入数组对象去重函数
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
	   TruePage:1,
	   positon:{
		   pro:'',
		   city:'',
		   detailAddress:'',
		   dateNow:''
	   },
	   userID:'',
	/*----------------以下为后台管理的全局数据----------------------------*/
		headerTag:[],
	},
	mutations: {
		changeHeaderTag(state,data){
			let obj = {
				val:data.name,
				path:data.router,
				index:data.index
			};
			state.headerTag.push(obj);
	        state.headerTag = deteleObject(state.headerTag);//数组对象的去重操作
		},
		clearHeaderTag(state){
			state.headerTag = [];
		},
		deleteOtherTag(state,path){
			let newTag = state.headerTag.filter((item)=>{
				return item.path === path
			});
			state.headerTag = newTag;
		},
		setToken(state, token) {
		    state.token = token
		    localStorage.token = token //同步存储token至localStorage
		},
		controlTheme(state){
			return state.theme = !state.theme 
		}
	},
	 getters : {
	  //获取token方法
	  //判断是否有token,如果没有重新赋值，返回给state的token
	  // getToken(state) {
	  //   if (!state.token) {
	  //     state.token = localStorage.getItem('token')
	  //   }
	  //   return state.token
	  //   }
	  }
})