import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './icons'
import './plugins/element.js'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/display.css'
import animated from 'animate.css' // 引入动画库
import './style/global.css' // 全局css样式
import NProgress from 'nprogress' // 导入进度条
import 'nprogress/nprogress.css'
import VueLazyload from 'vue-lazyload' //懒加载
import APlayer from '@moefe/vue-aplayer'; //音乐播放器
import './icons/icon.css'; //引入字体图标
import mavonEditor from 'mavon-editor' //markDown编辑器
import 'mavon-editor/dist/css/index.css'
import VueScrollReveal from 'vue-scroll-reveal';

import myMessage from './components/tool/index.js';//导入自己封装的组件
Vue.prototype.$myMessage = myMessage;

Vue.use(VueScrollReveal, {
	class: 'v-scroll-reveal',
	duration: 600,
	scale: 0.65,
	opacity: 0,
	distance: '0px',
	reset: false, // 滚动鼠标时，动画开关
	mobile: true,
	desktop: true,
	easing: 'ease-in-out',
	useDelay: 'once',
	origin: 'bottom',
	delay:0, // 延时时间
	rotate: {                           //5  100 5  与 5 5 100
		x: 5,
		y: 5,
		z:0,
	}, //执行方式（旋转）
	viewOffset: { // 视图偏移（把视图抽象成元素移动）
		top: 0,
		right: 5,
		bottom: 5,
		left: 0,
	},
});

Vue.use(mavonEditor);
Vue.use(APlayer, {
	defaultCover: 'https://github.com/u3u.png',
	productionTip: false,
});

Vue.use(VueLazyload, {
	preLoad: 1.3,
	loding: 'https://lmf401647220.oss-cn-beijing.aliyuncs.com/image/loading.gif',
	error: 'https://lmf401647220.oss-cn-beijing.aliyuncs.com/image/error.png',
	attempt: 2,
	lazyComponent: true
});

Vue.use(animated);
Vue.config.productionTip = false //去除Vue默认提示

axios.defaults.baseURL = 'https://www.limengfei.top/'; //线上环境
//axios.defaults.baseURL = 'http://127.0.0.1:3000/'; //本地环境			
axios.defaults.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$http = axios //Vue全局配置axios

Vue.directive('title', {
	inserted: function(el, binding) {
		document.title = el.dataset.title
	}
});

axios.interceptors.request.use(config => {
	let token = window.localStorage.getItem('token');
	if (token) {
		token = 'bearer' + ' ' + token.replace(/'|"/g, '') // 把token加入到默认请求参数中
		config.headers.common['Authorization'] = token
	}
	NProgress.start();
	config.headers.Authorization = window.sessionStorage.getItem('token'); //设置token
	return config;
}, function(error) {
	return Promise.reject(error); //处理错误情况
});

axios.interceptors.response.use(function(response) {
	NProgress.done(); //关闭隐藏条
	if (response.data.code === 10010 || response.data.code ===
		10011) { //①响应状态码 code:10010表示token过期 ②响应状态码 code:10011 表示token无效
		window.localStorage.removeItem('token'); // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
	} else if (response.data.token) { // 判断token是否存在，如果存在说明需要更新token
		window.localStorage.setItem('token', response.data.token); // 覆盖原来的token(默认一天刷新一次);
	}
	return response;
}, function(error) {
	return Promise.reject(error) //容错机制
});
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
