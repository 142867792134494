import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	getUserInfo
} from '../server/api/store.js'

const MyLove = ()=>import(/*webpackChunkName:"componentGroup"*/ '../views/clinet/love/mylove.vue'); //my love
const LiuYuan = ()=>import(/*webpackChunkName:"componentGroup"*/ '../views/clinet/love/liuyuan.vue'); //刘源
const MyLoveAnimation = ()=>import(/*webpackChunkName:"componentGroup"*/ '../views/clinet/love/loveAnimation.vue'); //动画玫瑰

const Home = () => import( /*webpackChunkName:"componentGroup1"*/ '../views/Home.vue') //路由懒加载
const Home_home = () => import( /*webpackChunkName:"componentGroup1"*/ '../views/clinet/childrenHome.vue'); //文章列表
const Home_practice = () => import( /*webpackChunkName:"componentGroup1"*/'../views/clinet/childrenPractice.vue'); //实战内容
const Article = () => import( /*webpackChunkName:"componentGroup1"*/'../views/clinet/article.vue'); //文章的内容页

const MusicLife = () => import( /*webpackChunkName:"componentGroup2"*/ '../views/clinet/MusicLife.vue'); //归档与音乐
const FriendLink = () => import(/*webpackChunkName:"componentGroup2"*/ '../views/clinet/FriendLink.vue'); //友情链接
const AboutMe = () => import(/*webpackChunkName:"componentGroup2"*/ '../views/clinet/aboutMe.vue'); //关于本站
const myShare = () =>import(/*webpackChunkName:"componentGroup2"*/ '../views/clinet/share.vue'); //我的分享
/*以下为 admin后台管理*/
const adminIndex = () => import( /*webpackChunkName:"componentGroup6"*/ '../views/admin/index.vue') //后台管理登录
const adminHome = () => import( /*webpackChunkName:"componentGroup6"*/ '../views/admin/home.vue') //后台管理首页
const adminHome_child = () => import( /*webpackChunkName:"componentGroup6"*/
	'../views/admin/homeChildren/home.vue') //首页内容
const adminUser_child = () => import( /*webpackChunkName:"componentGroup6"*/
	'../views/admin/homeChildren/user.vue') //用户内容
const adminAticlePub_child = () => import( /*webpackChunkName:"componentGroup6"*/
	'../views/admin/homeChildren/articlePub.vue') //文章发布

const adminAticleEdit_child = () => import( /*webpackChunkName:"componentGroup7"*/
	'../views/admin/homeChildren/artcileEdit.vue') //文章编辑
const adminFriendUrl_child = () => import( /*webpackChunkName:"componentGroup7"*/
	'../views/admin/homeChildren/friendUrl.vue') //友链管理
const adminMyMusci_child = () => import( /*webpackChunkName:"componentGroup7"*/
	'../views/admin/homeChildren/myMusic.vue') //音乐管理
const adminAboutMe_child = () => import( /*webpackChunkName:"componentGroup7"*/
	'../views/admin/homeChildren/aboutMe.vue') //关于本站和我

const adminTimeStamp = () => import( /*webpackChunkName:"componentGroup8"*/
	'../views/admin/homeChildren/timeStamp.vue') //时间线
const remarkAdmin = () => import( /*webpackChunkName:"componentGroup8"*/
	'../views/admin/homeChildren/remarkAdmin.vue') //评论管理
const shareCheck = () => import( /*webpackChunkName:"componentGroup8"*/
	'../views/admin/homeChildren/shareCheck.vue') //分享审核
const projectCombat = () => import( /*webpackChunkName:"componentGroup8"*/
	'../views/admin/homeChildren/projectCombat.vue') //项目实战
const operarionHistory = () => import( /*webpackChunkName:"componentGroup8"*/
	'../views/admin/homeChildren/operationHistory.vue') //操作记录
Vue.use(VueRouter);

const routes = [{
		path: '/',
		name: 'loading',
		redirect: '/home',
	},
	{
		path:'/mylove',
		name:'mylove',
		component:MyLove,
		meta:{
			title:'送给赵佳欣^_^',
		}
	},
	{
		path:'/zjx',
		name:'zjx',
		component:MyLoveAnimation,
		meta:{
			title:'看完小故事^_^'
		}
	},
	{
		path:'/liuyuan',
		name:'liuyuan',
		component:LiuYuan,
		meta:{
			title:'送给段嘉慧'
		}
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			keepAlive: true,
			title: '欢迎来到此小站'
		},
		children: [
			{
				path: '/home',
				component: Home_home,
				meta:{
					keepAlive:true
				}
			},
			{
				path:'/detail/:id', //动态路由,文章详情内容
				component:Article,
				meta:{
					keepAlive:true
				}
			},
			{
				path: '/practice',
				component: Home_practice,
				meta:{
					keepAlive:true,
					title:'项目实战'
				}
			},
			{
				path:'/file',
				component: MusicLife,
				meta:{
					keepAlive:true,
					title:'归档'
				}
			},
			{
				path:'/interaction',
				component:FriendLink,
				meta:{
					keepAlive:true,
					title:'友情链接'
				}
			},
			{
				path:'/about',
				component:AboutMe,
				meta:{
					keepAlive:true,
					title:'关于本站'
				}
			},
			{
				path:'/share',
				component:myShare,
				meta:{
					keepAlive:true,
					title:'故事分享╭（′▽‵）╭（′▽‵）╯嗨森'
				}
			}
		]
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/admin/login',
		component: adminIndex,
		name: '0',
		meta: {
			keepAlive: true,
			title: '后台管理登录'
		}
	},
	{
		path: '/admin/home',
		component: adminHome,
		children: [{
				path: '/admin/home',
				name: '1',
				component: adminHome_child,
				meta: {
					keepAlive: true,
					title: '欢迎来到首页'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/user',
				name: '2',
				component: adminUser_child,
				meta: {
					keepAlive: true,
					title: '用户管理'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/article',
				name: '3',
				component: adminAticlePub_child,
				meta: {
					keepAlive: true,
					title: '文章发布'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/articleAdmin',
				name: '4',
				component: adminAticleEdit_child,
				meta: {
					keepAlive: true,
					title: '文章管理'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/friendAdmin',
				name: '5',
				component: adminFriendUrl_child,
				meta: {
					keepAlive: true,
					title: '友链管理'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/musicAbout',
				name: '6',
				component: adminMyMusci_child,
				meta: {
					keepAlive: true,
					title: '音乐管理'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/myWebsite',
				name: '7',
				component: adminAboutMe_child,
				meta: {
					keepAlive: true,
					title: '关于本站与我'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/timeStamp',
				name: '8',
				component: adminTimeStamp,
				meta: {
					keepAlive: true,
					title: '时间线'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/remarkAdmin',
				name: '9',
				component: remarkAdmin,
				meta: {
					keepAlive: true,
					title: '评论管理'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/share',
				name: '10',
				component: shareCheck,
				mata: {
					keepAlive: true,
					title: '分享审核'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/combat',
				name: '11',
				component: projectCombat,
				meta: {
					keepAlive: true,
					title: '项目实战'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			},
			{
				path: '/admin/operation',
				name: '12',
				component: operarionHistory,
				meta: {
					keepAlive: true,
					title: '操作记录'
				},
				beforeEnter: (to, from, next) => {
					if (getUserInfo() !== null) {
						if (getUserInfo().token) {
							next();
						} else {
							next('/admin');
						}
					} else {
						next('/admin'); //返回登录页面
					}
				}
			}
		],
	}
];
const router = new VueRouter({
	routes,
	scrollBehavior(to, from, savedPosition) { //跳转回位 0，0
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});

router.beforeEach(async (to, from, next) => {
	if ((to.name === '0' && getUserInfo() !== null)) {
		next({
			name: '1'
		}); //直接去首页
	} else {
		next(); //返回登录
	}
});
const VueRouterPush = VueRouter.prototype.push //解决按钮重复点击的报错问题
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
};
export default router;