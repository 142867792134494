<template>
	<div id="app">
		<router-view />
		<!--路由占位符-->
	</div>
</template>
<script>
	window.onload = function() { //禁止移动端的放大与缩小
		document.addEventListener('touchstart', function(event) {
			if (event.touches.length > 1) {
				event.preventDefault()
			}
		})
		document.addEventListener('gesturestart', function(event) {
			event.preventDefault()
		})
	}
	export default {
		name: 'app',
		data() {
			return {
                theme:false,
			}
		},
		methods: {
			fnChangeMode() {
				if (this.theme) {
					window.document.documentElement.setAttribute('data-theme', 'dark')
				} else {
					window.document.documentElement.setAttribute('data-theme', 'light')
				}
			}
	    },
		mounted() {
			this.fnChangeMode();
		},
	}
</script>
<style>
	* {
		padding: 0;
		margin: 0;
	}

	@import './style/theme.css'
	/*引入暗模式主体样式*/
</style>
